import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Box, Button, Grid, TextField } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import { hasPermission } from "../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import { BrokerSearchCard } from "./components/BrokerSearchCard";
import { chunk } from "lodash";
import { Pagination } from '@mui/material';
var AllAbsBrokers = function (_a) {
    var _b;
    var response_data = _a.response_data;
    var auth = usePage().props.auth;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(response_data.absBrokers), absBrokers = _d[0], setAbsBrokers = _d[1];
    var _e = React.useState(1), page = _e[0], setPage = _e[1];
    var handleChange = function (event, value) {
        setPage(value);
    };
    var _f = useState(""), filter = _f[0], setFilter = _f[1];
    useEffect(function () {
        setPage(1);
        setAbsBrokers(response_data.absBrokers.filter(function (agent) { var _a, _b, _c, _d; return ((_b = (_a = agent === null || agent === void 0 ? void 0 : agent.user) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(filter.toLowerCase())) || ((_d = (_c = agent === null || agent === void 0 ? void 0 : agent.user) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(filter.toLowerCase())); }));
    }, [filter]);
    var currentPages = chunk(absBrokers, 15);
    return (React.createElement(Layout, { title: "All Brokers", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(PageTitle, { title: "All Brokers" }),
        React.createElement(Grid, { container: true, spacing: 2, justifyContent: "flex-end" },
            hasPermission(auth, "create broker") && (React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Link, { href: route("abs_brokers_form") },
                    React.createElement(Button, { type: "button", color: "primary", variant: "contained" }, "Create Broker")))),
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(TextField, { variant: "outlined", size: "small", label: "Filter By Name/Email", type: "text", name: "filterByName", defaultValue: filter, fullWidth: true, onChange: function (e) { return setFilter(e.target.value); } })),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(Pagination, { count: currentPages.length, page: page, onChange: handleChange })),
            React.createElement(Grid, { item: true, xs: 12 }, (_b = currentPages[page - 1]) === null || _b === void 0 ? void 0 : _b.map(function (broker) { return (React.createElement(Box, { mb: 2, key: broker.id },
                React.createElement(BrokerSearchCard, { broker: broker }))); })))));
};
export default AllAbsBrokers;
