import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LifetimeBadge } from "../../../components/IconLibrary";
var MemberHeader = function (_a) {
    var member = _a.member;
    return (React.createElement(Box, { mx: 2, my: 2 },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "h6" }, member.full_name),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#000" } },
                    "#",
                    member.id,
                    " ")),
            React.createElement(Grid, { container: true, item: true, xs: 4, alignItems: "flex-start", justifyContent: "flex-start" }, (member === null || member === void 0 ? void 0 : member.lifetime) == 1 && React.createElement(LifetimeBadge, null)))));
};
export default MemberHeader;
