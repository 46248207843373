var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import { usePage } from "@inertiajs/react";
import styled from "styled-components";
import { agentFilter } from "../../../utils/agentUtils";
var AgentInformation = function (props) {
    var _a;
    var control = props.control, errors = props.errors, agents = props.agents, app = props.app, deptType = props.deptType;
    var auth = usePage().props.auth;
    var agentsInDepartment = agentFilter(agents, deptType, 0, auth.user.id);
    var transferTo = agents.filter(function (el) { return el.id == app.transfer_user_id; }).shift() ||
        null;
    var assistedBy = agents.filter(function (el) { return el.id == app.assisted_by; }).shift() || null;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { component: Paper, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Agent Information")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(TransferAgentMessage, null,
                        "*Transfer agent defaults to ", (_a = auth === null || auth === void 0 ? void 0 : auth.user) === null || _a === void 0 ? void 0 :
                        _a.name)),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { error: "transfer_user_id" in errors, label: "Transfer Agent", name: "transfer_user_id", limitTags: 1, control: control, options: agentsInDepartment, rules: {
                            required: false,
                        }, value: transferTo, multiple: false })),
                app.formType !== "policy" && (React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { limitTags: 1, control: control, options: agentsInDepartment, rules: {
                            required: false,
                        }, multiple: false, error: "assisted_by" in errors, label: "Assisted By", value: assistedBy, name: "assisted_by" })))))));
};
var TransferAgentMessage = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: -10px 0 -5px 0;\n"], ["\n    margin: -10px 0 -5px 0;\n"])));
export default AgentInformation;
var templateObject_1;
