import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
import { helperFunctions } from "../../../Helpers";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import MemberLeadDetail from "./MemberLeadDetail";
import { AppLink } from "./AppLink";
var MemberLead = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    var member = _a.member, lead = _a.lead, leadTypes = _a.leadTypes, agents = _a.agents, states = _a.states, _9 = _a.showDefault, showDefault = _9 === void 0 ? false : _9, dispositions = _a.dispositions;
    var _10 = useState(showDefault), show = _10[0], setShow = _10[1];
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Link, { href: (lead === null || lead === void 0 ? void 0 : lead.member_id)
                        ? route("show_member", {
                            id: lead.member_id,
                        })
                        : "#", style: {
                        color: "#000",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, lead.member_spouse
                        ? "".concat((_b = member === null || member === void 0 ? void 0 : member.spouse_full_name) !== null && _b !== void 0 ? _b : "N/A", " (Spouse)")
                        : "".concat((_c = member === null || member === void 0 ? void 0 : member.full_name) !== null && _c !== void 0 ? _c : "N/A", " (Member)"))),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                    "#",
                    lead.member_id),
                (lead === null || lead === void 0 ? void 0 : lead.follow_up_date) && (React.createElement(Typography, { variant: "body2" },
                    "Follow Up:",
                    " ",
                    React.createElement("strong", null, helperFunctions.formatDateDashes(lead === null || lead === void 0 ? void 0 : lead.follow_up_date)))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" },
                    "Lead ID:",
                    React.createElement(Link, { href: "/member/lead/".concat(lead === null || lead === void 0 ? void 0 : lead.member_id, "/").concat(lead === null || lead === void 0 ? void 0 : lead.id) },
                        React.createElement("strong", null,
                            " ", lead === null || lead === void 0 ? void 0 :
                            lead.id))),
                React.createElement(Typography, { variant: "body2" },
                    (lead === null || lead === void 0 ? void 0 : lead.financial_insurance_application) != null ? (React.createElement(React.Fragment, null,
                        "Financial App ID: ",
                        React.createElement(AppLink, { member_id: lead === null || lead === void 0 ? void 0 : lead.member_id, app_id: (_d = lead === null || lead === void 0 ? void 0 : lead.financial_insurance_application) === null || _d === void 0 ? void 0 : _d.id, app_type: "financial_insurance_applications" }))) : null,
                    (lead === null || lead === void 0 ? void 0 : lead.health_insurance_application) != null ? (React.createElement(React.Fragment, null,
                        "Health App ID: ",
                        React.createElement(AppLink, { member_id: lead === null || lead === void 0 ? void 0 : lead.member_id, app_id: (_e = lead === null || lead === void 0 ? void 0 : lead.health_insurance_application) === null || _e === void 0 ? void 0 : _e.id, app_type: "health_insurance_applications" }))) : null,
                    (lead === null || lead === void 0 ? void 0 : lead.life_insurance_application) != null ? (React.createElement(React.Fragment, null,
                        "Life App ID: ",
                        React.createElement(AppLink, { member_id: lead === null || lead === void 0 ? void 0 : lead.member_id, app_id: (_f = lead === null || lead === void 0 ? void 0 : lead.life_insurance_application) === null || _f === void 0 ? void 0 : _f.id, app_type: "life_insurance_applications" }))) : null),
                React.createElement(Typography, { variant: "body2" },
                    ((_g = lead === null || lead === void 0 ? void 0 : lead.health_insurance_application) === null || _g === void 0 ? void 0 : _g.policy) != null ? (React.createElement(React.Fragment, null,
                        "Policy ID: ",
                        React.createElement(Link, { href: "/member/policy/".concat(lead === null || lead === void 0 ? void 0 : lead.member_id, "/").concat((_j = (_h = lead === null || lead === void 0 ? void 0 : lead.health_insurance_application) === null || _h === void 0 ? void 0 : _h.policy) === null || _j === void 0 ? void 0 : _j.id) },
                            React.createElement("strong", null,
                                " ", (_l = (_k = lead === null || lead === void 0 ? void 0 : lead.health_insurance_application) === null || _k === void 0 ? void 0 : _k.policy) === null || _l === void 0 ? void 0 :
                                _l.id)))) : null,
                    ((_m = lead === null || lead === void 0 ? void 0 : lead.life_insurance_application) === null || _m === void 0 ? void 0 : _m.policy) != null ? (React.createElement(React.Fragment, null,
                        "Policy ID: ",
                        React.createElement(Link, { href: "/member/policy/".concat(lead === null || lead === void 0 ? void 0 : lead.member_id, "/").concat((_p = (_o = lead === null || lead === void 0 ? void 0 : lead.life_insurance_application) === null || _o === void 0 ? void 0 : _o.policy) === null || _p === void 0 ? void 0 : _p.id) },
                            React.createElement("strong", null,
                                " ", (_r = (_q = lead === null || lead === void 0 ? void 0 : lead.life_insurance_application) === null || _q === void 0 ? void 0 : _q.policy) === null || _r === void 0 ? void 0 :
                                _r.id)))) : null,
                    ((_s = lead === null || lead === void 0 ? void 0 : lead.financial_insurance_application) === null || _s === void 0 ? void 0 : _s.policy) !=
                        null ? (React.createElement(React.Fragment, null,
                        "Policy ID: ",
                        React.createElement(Link, { href: "/member/policy/".concat(lead === null || lead === void 0 ? void 0 : lead.member_id, "/").concat((_u = (_t = lead === null || lead === void 0 ? void 0 : lead.financial_insurance_application) === null || _t === void 0 ? void 0 : _t.policy) === null || _u === void 0 ? void 0 : _u.id) },
                            React.createElement("strong", null,
                                " ", (_w = (_v = lead === null || lead === void 0 ? void 0 : lead.financial_insurance_application) === null || _v === void 0 ? void 0 : _v.policy) === null || _w === void 0 ? void 0 :
                                _w.id)))) : null),
                React.createElement(Typography, { variant: "body2" },
                    "Lead Type:",
                    React.createElement("strong", null,
                        " ", (_x = leadTypes === null || leadTypes === void 0 ? void 0 : leadTypes.find(function (el) { return (el === null || el === void 0 ? void 0 : el.id) == lead.lead_type_id; })) === null || _x === void 0 ? void 0 :
                        _x.name)),
                React.createElement(Typography, { variant: "body2" },
                    "State: ",
                    React.createElement("strong", null, (_y = lead === null || lead === void 0 ? void 0 : lead.state) === null || _y === void 0 ? void 0 : _y.name)),
                React.createElement(Typography, { variant: "body2" },
                    ((_z = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _z === void 0 ? void 0 : _z.insurance_product) && (React.createElement(React.Fragment, null, "Insurance Product: ")),
                    React.createElement("strong", null, (_0 = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _0 === void 0 ? void 0 : _0.insurance_product)))),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" }, "Disposition: "),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, (_2 = (_1 = lead === null || lead === void 0 ? void 0 : lead.disposition) === null || _1 === void 0 ? void 0 : _1.name) !== null && _2 !== void 0 ? _2 : "New Lead")),
                React.createElement(Typography, { variant: "body2" },
                    "Status: ",
                    React.createElement("strong", null, lead === null || lead === void 0 ? void 0 : lead.status)))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                lead.user_transferred && (React.createElement(Typography, { variant: "body2" },
                    "Transferred to:",
                    " ",
                    ((_3 = lead.user_transferred) === null || _3 === void 0 ? void 0 : _3.deleted_at) == null ? (React.createElement("strong", null, (_4 = lead.user_transferred) === null || _4 === void 0 ? void 0 : _4.name)) : (React.createElement("strong", null,
                        React.createElement("s", null, (_5 = lead.user_transferred) === null || _5 === void 0 ? void 0 : _5.name))))),
                lead.user_assisted && (React.createElement(Typography, { variant: "body2" },
                    "Assisted by: ", (_6 = lead.user_assisted) === null || _6 === void 0 ? void 0 :
                    _6.name)),
                React.createElement(Typography, { variant: "body2" },
                    "Created by: ", (_7 = lead.user_created) === null || _7 === void 0 ? void 0 :
                    _7.name),
                React.createElement(Typography, { variant: "body2" },
                    "Created at:",
                    " ",
                    helperFunctions.getDateTime(lead.created_at)),
                React.createElement(Typography, { variant: "body2" },
                    "Updated by: ", (_8 = lead.user_updated) === null || _8 === void 0 ? void 0 :
                    _8.name),
                React.createElement(Typography, { variant: "body2" },
                    "Updated at:",
                    " ",
                    helperFunctions.getDateTime(lead.updated_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Box, { my: 2 },
                React.createElement(Button, { onClick: function () { return setShow(!show); } }, show ? (React.createElement(ArrowUpward, { style: {
                        fontSize: 50,
                        color: "#035399",
                        textDecoration: "none",
                    } })) : (React.createElement(ArrowDownward, { style: { fontSize: 50, color: "#035399" } }))))),
        show && (React.createElement(MemberLeadDetail, { member: member, lead: lead, leadTypes: leadTypes, agents: agents, states: states, dispositions: dispositions }))));
};
export default MemberLead;
