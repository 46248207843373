import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import GenericAutoComplete from "./GenericAutoComplete";
import { agentFilter } from "../utils/agentUtils";
var SetTransferUserId = function (_a) {
    var _b, _c, _d;
    var data = _a.data, member = _a.member, uri = _a.uri, agents = _a.agents, appType = _a.appType;
    var _e = useState(false), openTransferUserId = _e[0], setOpenTransferUserId = _e[1];
    var leadTransferUserId = (_b = data === null || data === void 0 ? void 0 : data.user_transferred) !== null && _b !== void 0 ? _b : null;
    var _f = useForm(), control = _f.control, formErrors = _f.formState.errors, handleSubmit = _f.handleSubmit;
    var handleTransferUserId = function () {
        setOpenTransferUserId(true);
    };
    var agentsInDepartment = agentFilter(agents, (_c = data.lead_type_id) !== null && _c !== void 0 ? _c : (_d = data === null || data === void 0 ? void 0 : data.lead) === null || _d === void 0 ? void 0 : _d.lead_type_id, data.transfer_user_id, data.created_by);
    var submitTransferUserId = function (formData) {
        var _a;
        setOpenTransferUserId(false);
        router.post(uri, {
            id: data.id,
            type: appType,
            member_id: member.id,
            transfer_user_id: (_a = formData === null || formData === void 0 ? void 0 : formData.transfer_user_id) === null || _a === void 0 ? void 0 : _a.id,
            route: window.location.href,
        });
    };
    var handleCloseTransferUserId = function () {
        setOpenTransferUserId(false);
    };
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Button, { onClick: handleTransferUserId, variant: "contained", size: "small", fullWidth: true, style: {
                    backgroundColor: "#1871BF",
                    color: "#FFF",
                } }, "Transfer To")),
        React.createElement(Modal, { open: openTransferUserId, onClose: handleCloseTransferUserId },
            React.createElement(Dialog, { open: openTransferUserId, onClose: handleCloseTransferUserId },
                React.createElement(DialogTitle, null, "Transfer To"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(GenericAutoComplete, { limitTags: 1, name: "transfer_user_id", control: control, label: "Agents", options: agentsInDepartment, rules: {
                                    required: true,
                                }, error: "transfer_user_id" in formErrors, value: leadTransferUserId, multiple: false })))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseTransferUserId, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitTransferUserId), color: "primary" }, "Change"))))));
};
export default SetTransferUserId;
