import { Link, usePage } from "@inertiajs/react";
import { Button, Grid } from "@mui/material";
import React from "react";
import { agentHasRole } from "../../../utils/utils";
var MemberNav = function (_a) {
    var member = _a.member, lead_count = _a.lead_count, app_count = _a.app_count, document_count = _a.document_count, policy_count = _a.policy_count, order_count = _a.order_count;
    var url = usePage().url;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { container: true, spacing: 1, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/show/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: url.includes("/member/show/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    } }, "Member"))),
        !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/orders/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/member/orders/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Orders",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, order_count)))))),
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/leads/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/member/leads/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Leads",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, lead_count))))),
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/apps/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/member/apps/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Apps",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, app_count))))),
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/policies/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/member/policies/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Policies",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, policy_count))))),
        !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/member/show-documents/".concat(member.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/member/show-documents/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Documents",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, document_count))))))));
};
export default MemberNav;
