import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
import { helperFunctions } from "../../../Helpers";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import AppDetails from "./AppDetails";
import { AppLink } from "./AppLink";
import { formatAppType } from "../../../utils/utils";
var AppCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var member = _a.member, app = _a.app, leadTypes = _a.leadTypes, agents = _a.agents, states = _a.states, appType = _a.appType, noteType = _a.noteType, fileType = _a.fileType, insuranceCarriers = _a.insuranceCarriers, _z = _a.showDefault, showDefault = _z === void 0 ? false : _z;
    var _0 = useState(showDefault), show = _0[0], setShow = _0[1];
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Link, { href: (app === null || app === void 0 ? void 0 : app.member_id)
                        ? route("show_member", {
                            id: app.member_id,
                        })
                        : "#", style: {
                        color: "#000",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, app.member_spouse
                        ? "".concat((_b = member === null || member === void 0 ? void 0 : member.spouse_full_name) !== null && _b !== void 0 ? _b : "N/A", " (Spouse)")
                        : "".concat((_c = member === null || member === void 0 ? void 0 : member.full_name) !== null && _c !== void 0 ? _c : "N/A", " (Member)"))),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                    "#",
                    app.member_id),
                (app === null || app === void 0 ? void 0 : app.follow_up_date) && (React.createElement(Typography, { variant: "body2" },
                    "Follow Up:",
                    " ",
                    React.createElement("strong", null, helperFunctions.formatDateDashes(app === null || app === void 0 ? void 0 : app.follow_up_date)))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" },
                    "App ID:",
                    React.createElement(AppLink, { member_id: app === null || app === void 0 ? void 0 : app.member_id, app_id: app === null || app === void 0 ? void 0 : app.id, app_type: formatAppType(appType) })),
                ((_d = app === null || app === void 0 ? void 0 : app.lead) === null || _d === void 0 ? void 0 : _d.id) != undefined ? (React.createElement(Typography, { variant: "body2" },
                    "Lead ID:",
                    React.createElement(Link, { href: "/member/lead/".concat((_e = app === null || app === void 0 ? void 0 : app.lead) === null || _e === void 0 ? void 0 : _e.member_id, "/").concat((_f = app === null || app === void 0 ? void 0 : app.lead) === null || _f === void 0 ? void 0 : _f.id) },
                        React.createElement("strong", null,
                            " ", (_g = app === null || app === void 0 ? void 0 : app.lead) === null || _g === void 0 ? void 0 :
                            _g.id)))) : null,
                ((_h = app === null || app === void 0 ? void 0 : app.policy) === null || _h === void 0 ? void 0 : _h.id) != undefined ? (React.createElement(Typography, { variant: "body2" },
                    "Policy ID:",
                    React.createElement(Link, { href: "/member/policy/".concat(app === null || app === void 0 ? void 0 : app.member_id, "/").concat((_j = app === null || app === void 0 ? void 0 : app.policy) === null || _j === void 0 ? void 0 : _j.id) },
                        React.createElement("strong", null,
                            " ", (_k = app === null || app === void 0 ? void 0 : app.policy) === null || _k === void 0 ? void 0 :
                            _k.id)))) : null,
                React.createElement(Typography, { variant: "body2" },
                    "Lead Type:",
                    React.createElement("strong", null,
                        " ", (_l = leadTypes === null || leadTypes === void 0 ? void 0 : leadTypes.find(function (el) { var _a; return (el === null || el === void 0 ? void 0 : el.id) == ((_a = app === null || app === void 0 ? void 0 : app.lead) === null || _a === void 0 ? void 0 : _a.lead_type_id); })) === null || _l === void 0 ? void 0 :
                        _l.name)),
                React.createElement(Typography, { variant: "body2" },
                    ((_o = (_m = app === null || app === void 0 ? void 0 : app.lead) === null || _m === void 0 ? void 0 : _m.extra_data_json) === null || _o === void 0 ? void 0 : _o.insurance_product) && (React.createElement(React.Fragment, null, "Insurance Product: ")),
                    React.createElement("strong", null, (_q = (_p = app === null || app === void 0 ? void 0 : app.lead) === null || _p === void 0 ? void 0 : _p.extra_data_json) === null || _q === void 0 ? void 0 : _q.insurance_product)))),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Box, { my: 2 },
                React.createElement(Typography, { variant: "body2" }, "Disposition: "),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("strong", null, (_s = (_r = app === null || app === void 0 ? void 0 : app.disposition) === null || _r === void 0 ? void 0 : _r.name) !== null && _s !== void 0 ? _s : "New App")))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Box, { my: 2 },
                app.user_transferred && (React.createElement(Typography, { variant: "body2" },
                    "Transferred to:",
                    " ",
                    ((_t = app.user_transferred) === null || _t === void 0 ? void 0 : _t.deleted_at) == null ? (React.createElement("strong", null, (_u = app.user_transferred) === null || _u === void 0 ? void 0 : _u.name)) : (React.createElement("strong", null,
                        React.createElement("s", null, (_v = app.user_transferred) === null || _v === void 0 ? void 0 : _v.name))))),
                app.user_assisted && (React.createElement(Typography, { variant: "body2" },
                    "Assisted by: ", (_w = app.user_assisted) === null || _w === void 0 ? void 0 :
                    _w.name)),
                React.createElement(Typography, { variant: "body2" },
                    "Created by: ", (_x = app.user_created) === null || _x === void 0 ? void 0 :
                    _x.name),
                React.createElement(Typography, { variant: "body2" },
                    "Created at:",
                    " ",
                    helperFunctions.getDateTime(app.created_at)),
                React.createElement(Typography, { variant: "body2" },
                    "Updated by: ", (_y = app.user_updated) === null || _y === void 0 ? void 0 :
                    _y.name),
                React.createElement(Typography, { variant: "body2" },
                    "Updated at:",
                    " ",
                    helperFunctions.getDateTime(app.updated_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Box, { my: 2 },
                React.createElement(Button, { onClick: function () { return setShow(!show); } }, show ? (React.createElement(ArrowUpward, { style: {
                        fontSize: 50,
                        color: "#035399",
                        textDecoration: "none",
                    } })) : (React.createElement(ArrowDownward, { style: { fontSize: 50, color: "#035399" } }))))),
        show && (React.createElement(AppDetails, { member: member, app: app, appType: appType, noteType: noteType, fileType: fileType, leadTypes: leadTypes, agents: agents, states: states, insuranceCarriers: insuranceCarriers }))));
};
export default AppCard;
